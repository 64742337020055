import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Header from './dashboard/Header';
import Sidebar from './dashboard/Sidebar';
import Footer from './dashboard/footer';

export default function AuthLayout({ children }) {
  const { pathname } = useRouter();
  const [dynamicClass, setDynamicClass] = useState('home');

  useEffect(() => {
    if (pathname) {
      const secondUri = pathname.split('/')[1];
      if (secondUri) {
        setDynamicClass(secondUri);
      } else {
        setDynamicClass('home');
      }
    }

  }, [pathname]);

  return (
    <div className={`${dynamicClass}-pageName`}>

      {children}

    </div>
  );
}